<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <!-- Brand logo-->
    <b-link class="brand-logo mb-2">
      <h2 class="brand-text text-primary ml-1">
        <b-img fluid :src="logoImg" alt="Logo da Agenda"></b-img>
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-2">O CRP da Unidade Gestora não é mais válido</h2>
        <h4>O acesso ao sistema está bloqueado✋</h4>
        <h4 class="mb-2">Adicione um novo CRP abaixo</h4>

        <b-card border-variant="primary">
          <b-card-text class="text-black">
            <validation-observer ref="crpRules" tag="form">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Data de validade" label-for="crpValidade">
                    <validation-provider #default="{ errors }" name="Data de validade" rules="required">
                      <cleave
                        id="crpValidade"
                        class="form-control"
                        type="text"
                        placeholder="DD/MM/AAAA"
                        v-model="dados.crpValidade"
                        :options="options.cleaveDate"
                        :raw="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Status do CRP" label-for="crpStatus">
                    <b-form-input id="crpStatus" type="text" :readonly="true" v-model="dados.crpStatus" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <div class="flex w-full items-center justify-center" id="app">
                    <div class="p-12 bg-gray-100" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                      <input
                        type="file"
                        multiple
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 p-1 overflow-hidden absolute mt-4"
                        @change="onChange"
                        ref="file"
                      />
                    </div>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="flex w-full items-center justify-center" id="app">
                    <div class="p-12 bg-gray-100" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                      <ul v-if="this.filelist.length" v-cloak>
                        <!-- TODO: Verificar esse erro de sintaxe -->
                        <ul class="p-1" style="text-align: right" v-for="file in filelist">
                          {{
                            file.name
                          }}
                          <b-button class="ml-1" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">
                            Excluir
                          </b-button>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </validation-observer>
          </b-card-text>
        </b-card>

        <p class="mb-1">O documento anexado acima passará por uma auditoria, para confirmar sua veracidade.</p>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  import { BLink, BImg, BButton, BRow, BCol, BFormGroup, BFormInput, BCard, BCardText } from 'bootstrap-vue'
  import { getHomeRouteForLoggedInUser } from '@/auth/utils'
  import { formatarData } from '@/libs/utils'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Cleave from 'vue-cleave-component'
  import moment from 'moment'
  import useJwt from '@/auth/jwt/useJwt'
  import { required } from '@validations'

  export default {
    components: {
      BLink,
      BImg,
      BButton,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BCard,
      BCardText,
      ToastificationContent,
      Cleave,
      moment,
      ValidationProvider,
      ValidationObserver,
      formatarData,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        downImg: require('@/assets/images/pages/Unauthorized.png'),
        logoImg: require('@/assets/images/pages/logoAgenda.png'),
        crpOpcoes: [
          { value: 'Valido', text: 'Valido' },
          { value: 'NaoValido', text: 'Não Valido' },
        ],
        dados: {},
        filelist: [],
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        required,
      }
    },
    computed: {
      imgUrl() {
        this.downImg = require('@/assets/images/pages/Unauthorized.png')
        return this.downImg
      },
      logoAgenda() {
        this.logoImg = require('@/assets/images/pages/logoAgenda.png')
        return this.logoImg
      },
    },
    mounted() {
      this.carregarGrid()
    },
    methods: {
      loginRoute() {
        const user = JSON.parse(localStorage.getItem('userData'))
        return getHomeRouteForLoggedInUser(user ? user.role : null)
      },
      carregarGrid() {
        if (this.userData.institutoSelecionado && this.userData.role == 'master') {
          var inst = this.userData.institutoSelecionado
          useJwt
            .edit(`cadastro/instituto`, inst)
            .then((response) => {
              this.dados = {
                ...response.data,
                crpValidade: formatarData(response.data.crpValidade),
              }
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      onChange() {
        this.filelist = [...this.$refs.file.files]
      },
      remove(i) {
        this.filelist.splice(i, 1)
      },
      dragover(event) {
        event.preventDefault()
        // Add some visual fluff to show the user can drop its files
        if (!event.currentTarget.classList.contains('bg-green-300')) {
          event.currentTarget.classList.remove('bg-gray-100')
          event.currentTarget.classList.add('bg-green-300')
        }
      },
      dragleave(event) {
        // Clean up
        event.currentTarget.classList.add('bg-gray-100')
        event.currentTarget.classList.remove('bg-green-300')
      },
      drop(event) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange() // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.add('bg-gray-100')
        event.currentTarget.classList.remove('bg-green-300')
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
